import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'mobx-react'
import store from './store'

import './index.scss'
import 'assets/styles/theme.scss'
import 'assets/styles/styles.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider {...store}>
    <App />
  </Provider>
)

reportWebVitals()
