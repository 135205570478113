import React from 'react'
import { BrowserRouter } from 'components/BrowserRouter'
import Loading from 'components/Loading'
import { RouteContainer } from 'containers/RouteContainer'
import { AppContainer } from 'containers/AppContainer'
import { Toast } from 'components/Toast'
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  console.log('ENVIRONMENT:', process.env.REACT_APP_ENVIRONMENT)
  return (
    <CacheBuster
      currentVersion={packageInfo.version} 
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<Loading active />}
    >
      <React.Fragment>
        <BrowserRouter>
          <AppContainer>
            <RouteContainer />
          </AppContainer>
        </BrowserRouter>
        <Loading />
        <Toast />
      </React.Fragment>
    </CacheBuster>
  )
}

export default App
